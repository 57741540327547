import { ButtonYellow } from "../app/Buttons";

export const HeroCourses = () => {
  return (
    <div className="bg-hero w-full flex flex-col justify-center items-center md:min-h-screen min-h-screen md:px-0 px-7">
      <div>
        <h1 className="lg:text-6xl md:text-5xl text-4xl font-bold text-proGray1 text-center">
          Piensa en <span className="text-proYellow">inglés,</span>
        </h1>
        <br className="sm:flex hidden" />
        <h1 className="lg:text-6xl md:text-5xl text-4xl font-bold text-proGray1 text-center">
          Piensa en <span className="text-proBlue">PRO</span>
          <span className="text-proRed">learn</span>
        </h1>
        <p className="text-center pt-7 text-lg">¡Echale un vistazo a nuestros cursos!</p>
      </div>

      <div className="sm:pt-16 pt-10">
      <ButtonYellow to="/contacto">Contáctanos</ButtonYellow>
      </div>
    </div>
  );
};
