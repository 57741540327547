export const Truble = () => {
  return (
    <section className="flex justify-center items-center flex-col-reverse sm:flex sm:flex-row sm:space-x-10 lg:px-28 md:px-14 px-7 sm:py-28 py-14 min-h-screen">
      <div className="md:w-2/5">
        <img
          src="images/home/caracteristicas.png"
          alt="Trouble"
          className="w-full"
        />
      </div>

      <div className="sm:w-1/2 space-y-8 mb-5 sm:mb-0">
        <h1 className="lg:text-5xl md:text-4xl text-2xl font-bold text-proGray1">
          El {""}
          <span className="text-proBlue">don </span> del{" "}
          <span className="text-proRed">inglés:</span> convertir palabras en{" "}
          <span className="text-proYellow">prosperidad</span>
        </h1>
        <p className="sm:text-base text-sm">
          Se parte de PRO Learn y descubre cómo el inglés tiene el poder
          de transformar tu vida. Si eres una persona bilingüe y dominas el
          idioma mas hablado del mundo, aumenta el valor en tu currículum
          y así, accedes a mejores oportunidades laborales. ¡Es la
          fórmula perfecta!.
        </p>
        <div className="border-2 border-dashed sm:w-3/4 border-gray-200 w-full" />

        <div className="text-xs gap-4 flex flex-wrap font-semibold">
          <span className="flex items-center">
            <i className="fas fa-thumbs-up bg-proBlue text-white flex justify-center items-center w-8 h-8 rounded-full  mr-2" />
            Calidad
          </span>
          <span className="flex items-center">
            <i className="fas fas fa-gears bg-proRed text-white flex justify-center items-center w-8 h-8 rounded-full mr-2" />
            Eficacia
          </span>
          <span className="flex items-center">
            <i className="fas fa-hourglass bg-proYellow text-white flex justify-center items-center w-8 h-8 rounded-full mr-2" />
            Experiencia
          </span>
        </div>
      </div>
    </section>
  );
};
