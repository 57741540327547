import { dataSocial } from "../utils/FullData";
import { NormalLink } from "./app/Enlaces";

export default function Footer() {
  return (
    <footer className=" space-y-8 lg:px-28 md:px-14 px-7 py-5 bg-gray-200 ">
      <div className="grid grid-cols-1 lg:grid-cols-2 space-y-7 sm:space-y-7">
        <div>
          <div className="w-28">
            <img src="images/app/logo_completo.png" alt="Logo Prolearn" />
          </div>

          <div className="mt-6">
            <p className="text-base text-proGray">
              <span className="font-semibold">¿Aprendiste algo nuevo hoy?</span>
              <br />
              Somos los mejores enseñando inglés.
            </p>
          </div>

          <div className="mt-8 flex space-x-3">
            {dataSocial.map((item, index) => {
              return (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <i
                    className={`${item.icon} text-white rounded-full ${item.bg} hover:opacity-80 flex items-center justify-center w-8 h-8 transition-transform transform scale-100 hover:scale-110 duration-300`}
                  />
                </a>
              );
            })}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 space-y-7 sm:space-y-0">
          <div className="flex flex-col text-sm space-y-5 text-proGray">
            <h3 className="text-proBlue font-bold text-base uppercase border-b-2 border-proBlue sm:w-1/2 w-1/4">
              Enlaces
            </h3>
            <NormalLink to="/">Inicio</NormalLink>
            <NormalLink to="/sobre-nosotros">Sobre nosotros</NormalLink>
            <NormalLink to="/cursos">Cursos</NormalLink>
            <NormalLink to="/certificacion-TOEFL-ITP">
              Certificación TOEFL ITP
            </NormalLink>
            <NormalLink to="/contacto">Contacto</NormalLink>
          </div>

          <div className="flex flex-col text-sm space-y-5 text-proGray">
            <h3 className="text-proBlue font-bold text-base uppercase border-b-2 border-proBlue sm:w-1/2 w-1/4">
              Horario
            </h3>
            <p>
              <i className="fas fa-calendar text-xs mr-2" />
              Lunes - Viernes
            </p>
            <p>
              <i className="fas fa-clock text-xs mr-2" />
              10:30 a.m - 5:30 p.m
            </p>
            <p>
              <i className="fas fa-calendar text-xs mr-2" />
              Sabado - Domingo
            </p>
            <p>
              <i className="fas fa-clock text-xs mr-2" />
              9:00 a.m - 5:00 p.m
            </p>
          </div>

          <div className="flex flex-col text-sm space-y-5 text-proGray">
            <h3 className="text-proBlue font-bold text-base uppercase border-b-2 border-proBlue sm:w-1/2 w-1/3">
              Contacto
            </h3>

            <p>
              <i className="fas fa-envelope text-xs mr-1" />
              soyprolearn@gmail.com
            </p>
            <a
              href="https://api.whatsapp.com/send?phone=527122592802&app=facebook&entry_point=page_cta"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-proBlue hover:border-b hover:border-proBlue w-fit transition-colors duration-300"
            >
              <i className="fab fa-whatsapp text-xs mr-1" /> +52 712 259 2802
            </a>
            <a
              href="https://www.google.com/maps/place/ProLearn/@19.553939,-99.756727,15z/data=!4m5!3m4!1s0x0:0xebbf512024d95d4!8m2!3d19.5539387!4d-99.756727?hl=es"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-proBlue hover:border-b hover:border-proBlue w-fit transition-colors duration-300"
            >
              <i className="fas fa-map-marker-alt text-xs mr-1" /> Av. de los
              Maestros No.18, Plaza Comercial Santo Domingo, 50740 Ixtlahuaca de
              Rayón, Méx.
            </a>
          </div>
        </div>
      </div>

      <div className="flex md:flex-row flex-col justify-between border-t border-gray-400 text-xs">
        <p className=" text-gray-500 pt-3">
        Created by Alex &copy; 2018. Prolearn Servicios Educativos S.C.
        </p>
        <div className="text-gray-500 pt-3 space-x-7">
          <NormalLink
            to="/terminos-y-condiciones"
          >
            Términos y Condiciones
          </NormalLink>
          <NormalLink
            to="/aviso-de-privacidad"
          >
            Avíso de Privacidad
          </NormalLink>
          <NormalLink
            to="/aviso-de-privacidad"
          >
            Politicas de Cookies
          </NormalLink>
        </div>
      </div>
    </footer>
  );
}
