import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Seo from "../seo/Seo";
import { dataTerminosCondiciones } from "../utils/FullData";

export default function Terminos() {
  return (
    <div>
      <Seo
        title="Terminos y Condiciones"
        description="Explora nuestros términos y condiciones para comprender completamente tus derechos y responsabilidades al utilizar los servicios de ProLearn."
      />
      <NavBar bgColor="bg-white" />
      <div className="lg:px-28 md:px-14 px-7 sm:py-36 py-28 md:space-y-20 space-y-14">
        <h1 className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl font-bold text-proGray1">
          <span className="text-proBlue">Términos</span> y {""}
          <span className="text-proRed">Condiciones</span>
        </h1>
        <div className="md:px-12 space-y-10">
          {dataTerminosCondiciones.map((item, index) => (
            <div key={index} className=" space-y-5">
              <h2 className="md:text-3xl text-xl font-bold text-proGray1 uppercase">
                {item.title}
              </h2>
              <p className="text-proGray1 md:text-lg text-sm">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
