import { dataGridRegister, dataGridTypeTOEFL } from "../../utils/FullData";

export const RegisterCertification = () => {
  return (
    <>
      <section className="min-h-screen w-full xl:px-28  md:px-14 px-7 sm:py-28 py-14 ">
        <div className="flex items-center justify-center">
          <div className="">
            <div className="grid grid-rows-2 gap-10">
              <GridTypeTOEFL />
              <GridRegister />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const GridTypeTOEFL = () => {
  return (
    <div className="space-y-14">
      <h1 className="lg:text-4xl md:text-2xl text-xl font-bold text-proGray1 text-center">
        Modalidad
      </h1>
      <div className="grid lg:grid-cols-3 lg:gap-16 gap-10">
        {dataGridTypeTOEFL.map((item, index) => (
          <div key={index} className="space-y-8 border border-gray-400 rounded-2xl xl:p-10 p-5">
            <div className="flex flex-col items-center">
              <span
                className={`${item.bg} font-bold text-white rounded-full h-12 w-12 flex items-center justify-center`}
              >
                <i className={`${item.icon}`} />
              </span>
            </div>
            <div className="space-y-2">
              <h1 className="text-proGray1 text-lg font-bold text-center">{item.title}</h1>
              <p className="text-center">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const GridRegister = () => {
  return (
    <div className="space-y-14">
      <h1 className="lg:text-4xl md:text-2xl text-xl font-bold text-proGray1 text-center">
        Registro
      </h1>
      <div className="grid lg:grid-cols-3 lg:gap-16 gap-10">
        {dataGridRegister.map((item, index) => (
          <div key={index} className="space-y-8 border border-gray-400 rounded-2xl xl:p-10 p-5">
            <div className="flex items-center justify-center">
              <span
                className={`${item.bg} font-bold text-white rounded-full h-12 w-12 flex items-center justify-center`}
              >
                {item.number}
              </span>
            </div>

            <div className="space-y-2">
              <h1 className="text-proGray1 font-bold text-center text-lg">
                {item.title}
              </h1>
              <p className="text-center">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
