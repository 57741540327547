import { dataAboutInfo } from "../../utils/FullData";

export const AboutInfo = () => {
  return (
    <section className=" flex flex-col items-center justify-center ">
      <div className="">
        {dataAboutInfo.map((info, index) => (
          <div
            key={index}
            className={`flex ${info.flexrow} ${info.flexcol} ${info.bg} items-center justify-between lg:px-28 md:px-14 px-7 py-14`}
          >
            <div className="">
              <img
                src={info.img}
                alt="Imagen about"
                className="rounded-2xl object-cover lg:w-[600px] w-full py-8"
              />
            </div>
            <div className={`lg:w-1/2 lg:pb-0 space-y-5`}>
              <h1 className="text-proGray1 font-bold sm:text-3xl text-2xl">
                {info.title} {""}
                <span className={info.colorSpan}>{info.span}</span>
              </h1>
              <p>{info.description}</p>
            </div>
          </div>
        ))}

        <section className="rounded-2xl flex lg:flex-row flex-col-reverse items-center justify-between lg:px-28 md:px-14 px-7 py-14">
          <div className="">
            <img
              src="images/about/about1.png"
              alt="Imagen about"
              className="rounded-2xl object-cover lg:w-[600px] w-full py-8"
            />
          </div>
          <div className="lg:w-1/2 lg:pb-0 space-y-5">
            <h1 className="text-proGray1 font-bold sm:text-3xl text-2xl">
              Objetivos Generales {""}
              <span className="text-proBlue">PRO</span>
              <span className="text-proRed">Learn</span>
            </h1>
            <ul className="list-disc sm:ml-10 ml-5">
              <li>
                Ofrecer programas especializados que se adapten a las
                necesidades específicas de diferentes sectores profesionales.
              </li>
              <li>
                Fomentar un ambiente de aprendizaje inclusivo y colaborativo.
              </li>
              <li>
                Preparar a los estudiantes para alcanzar altos puntajes en
                exámenes de certificación.
              </li>
              <li>
                Mantener un programa innovador de enseñanza del inglés para
                mejorar la experiencia de aprendizaje y la retención del idioma.
              </li>
              <li>
                Mejorar el dominio general del inglés en nuestra zona de
                influencia.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </section>
  );
};
