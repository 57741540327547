import { Autoplay } from "swiper/modules";
import { dataReviews } from "../../utils/FullData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

export const Reviews = () => {
  return (
    <section className="w-full sm:flex items-center sm:space-x-20 lg:px-28 md:px-14 px-7 sm:py-28 py-14 sm:min-h-screen">
      <div className="sm:w-1/2 space-y-16">
        <h1 className="text-proGray1 font-bold lg:text-5xl md:text-4xl text-2xl text-center">
          <span className="text-proBlue">Testimonios </span> de
          <span className="text-proRed"> nuestros </span>estudiantes
        </h1>

        <p className="text-sm text-center ">
          Explora los testimonios y experiencias de nuestros estudiantes acerca
          de nuestros servicios.
        </p>
      </div>

      <Swiper
        mousewheel={true}
        modules={[Autoplay]}
        direction="vertical"
        slidesPerView={1}
        spaceBetween={10}
        grabCursor={true}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="sm:h-[500px] sm:w-1/2 h-[250px] mt-10"
      >
        {dataReviews.map((review, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="rounded-2xl p-5 border-2 border-[#F5F5F5] shadow-sm h-60 relative bg-white">
                <div className=" flex flex-col items-center">
                  <div className="w-16 mb-3">
                    <img
                      src={review.img}
                      alt=""
                      className={`w-full object-cover border-2 ${review.border} rounded-full p-1`}
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <h1 className="font-bold text-sm">{review.name}</h1>
                    <p className="text-xs text-center">Estudiante</p>
                    <div>
                      {[...Array(review.stars)].map((_, index) => (
                        <i
                          key={index}
                          className="fas fa-star text-proYellow text-xs"
                        ></i>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="">
                  <i className="fas fa-quote-left text-proGray text-3xl absolute left-3 top-32 " />
                  <p className="md:text-base text-xs pt-3 text-center">{review.description}</p>
                  <i className="fas fa-quote-right text-proGray text-3xl absolute right-3 top-32" />
                </div>
                <span
                  className={`absolute top-0 right-0 ${review.bg} text-white rounded-full text-[10px] flex items-center justify-center w-[70px] h-5`}
                >
                  {review.espect}
                </span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
