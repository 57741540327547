export const dataSocial = [
  {
    icon: "fab fa-facebook-f",
    bg: "bg-[#1877F2]",
    url: "https://www.facebook.com/ProLearnIxtla?mibextid=LQQJ4d",
  },
  {
    icon: "fab fa-instagram",
    bg: "bg-[#FF007F]",
    url: "https://www.instagram.com/prolearn.expertoseningles?igsh=eDgyY3NjMG12aTc1&utm_source=qr",
  },
  {
    icon: "fab fa-tiktok",
    bg: "bg-black",
    url: "https://www.tiktok.com/@pro.learn?_t=8k3rAAAhFa9&_r=1",
  },
  {
    icon: "fab fa-whatsapp",
    bg: "bg-[#25D366]",
    url: "https://api.whatsapp.com/send?phone=7122592802&text=👋 ¡Hola! Me gustaría obtener más información sobre los cursos de inglés que ofrecen en su escuela. ¿Podrían proporcionarme detalles sobre horarios, niveles disponibles y precios? ¡Gracias! 📚🌟",
  },
];

export const dataReviews = [
  {
    name: "Maria de los Angeles Seferino Bernal",
    description:
      "Es una escuela genial para aprender inglés. Las clases son dinámicas y divertidas; van de la mano con el aprendizaje.",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMQGfk293wkry4Hahai8yRHQCeirk55pR6z3xxiAGTr0uiM=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Excelente",
    bg: "bg-proBlue",
    border: "border-proBlue",
  },

  {
    name: "Martin López Robles",
    description:
      "Es una escuela de inglés increíble, muy buenos docentes gran ambiente y una excelente atención.",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMR7xEEN-eRijvaVoKoJ0VGciQ-lXLXLv5T3lhrYE504p9s=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Genial",
    bg: "bg-proRed",
    border: "border-proRed",
  },
  {
    name: "Orta González Yovana",
    description:
      "Pro Learn es una excelente opción para aprender inglés, me agrada la forma de enseñar de los docentes, además de que las clases son muy dinámicas!",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMRyXZOfVS8ohu-Cpo9NYprnbrI9brI07F0BjzM5yvDgOHQ=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Bueno",
    bg: "bg-proYellow",
    border: "border-proYellow",
  },
  {
    name: "Tania Feliciano",
    description:
      "Muy buena institución para aprender Inglés.Docentes comprometidos.",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMQEQEk5HgK-mN91xbKyjf5tPIgShytwETpWerVobmT0QQ=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Espléndido",
    bg: "bg-proBlue",
    border: "border-proBlue",
  },
  {
    name: "Osny",
    description:
      "En verdad una escuela donde todos pueden aprender inglés, obtener una certificación y seguir creciendo académicamente",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMTwQ1Zm-9UuFbc6Wltqk5uORCcXJYu1T15zTs78CwMhjkE0=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Estupendo",
    bg: "bg-proRed",
    border: "border-proRed",
  },
  {
    name: "Luis Piña",
    description:
      "Las clases son interactivas e interesantes un buen lugar para aprender Ingles",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMSFPfLPX9fjnnzUUW47pKxEOChIlLBvhM3Ee1-h04lhZ-g=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Grandioso",
    bg: "bg-proYellow",
    border: "border-proYellow",
  },
  {
    name: "Kari Miranda",
    description:
      "Exactamente manera de aprender inglés, con clases muy dinámicas y un gran ambiente.",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMT0Q7Kz4nHdJF-8SBpXNnXMOWtwbiznNFSiD_le6KlIXw=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Perfecto",
    bg: "bg-proBlue",
    border: "border-proBlue",
  },
  {
    name: "Fernanda Hernández",
    description:
      "Es una excelente escuela, con profesores capacitados y la mejor atención.",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMTvC_Y20BD2pyRyaUHphjVXcgzjtS-1qxvFrz0lon__6Q=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Fabuloso",
    bg: "bg-proRed",
    border: "border-proRed",
  },
  {
    name: "Jaqueline Sánchez Jiménez",
    description: "Excelente opción para aprender inglés.",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMQWJMOJtOKhxeDQwAZoSnDwlfS5kOlR3GYKhbs2-hsBbgk=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Maravilloso",
    bg: "bg-proYellow",
    border: "border-proYellow",
  },
  {
    name: "Maricarmen Jacinto",
    description: "Gran atención, y clases super activas!",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMR-l31Dmb8JVS_vQKQ4-CJJYfZYKKIY92haeL7zT4g-1Ng=w60-h60-p-rp-mo-br100",
    stars: 5,
    espect: "Increíble",
    bg: "bg-proBlue",
    border: "border-proBlue",
  },
];

export const dataCourses = [
  {
    type: "Curso",
    name: "TOEFL ITP",
    description:
      "El objetivo del curso TOEFL ITP es proveer las herramientas necesarias para que obtengas el puntaje que estás buscando.",
    bg: "bg-proBlue",
    border: "border-proBlue",
    text: "text-proBlue",
    img: "images/courses/course1.jpg",
  },
  {
    type: "Curso",
    name: "ai-ae",
    description:
      "Aprendizaje de Inglés de Alta Eficacia basado en el poder de la mente para aprender de manera natural y relajada a través de un sistema de estudio altamente eficaz. El curso ai-ae se compone de 8 fases con objetivos específicos.",
    bg: "bg-proRed",
    border: "border-proRed",
    text: "text-proRed",
    img: "images/courses/course2.jpg",
  },
  {
    type: "Examen para",
    name: "Certificación TOEFL ITP",
    description:
      "El examen TOEFL ITP es una evaluación internacionalmente reconocida de tus habilidades en inglés. Obtén tu certificación y desbloquea oportunidades en educación y carrera. ¡Inscríbete hoy para demostrar tu dominio del inglés!",
    bg: "bg-proYellow",
    border: "border-proYellow",
    text: "text-proYellow",
    img: "images/courses/course3.jpg",
  },
];

export const dataBenifits = [
  {
    tittle: "Clases personalizadas",
    description:
      "Adaptamos el aprendizaje a tus necesidades individuales para un aprendizaje efectivo y significativo.",
    icon: "fas fa-chalkboard-teacher",
    bg: "bg-proBlue",
  },

  {
    tittle: "Actividades creativas",
    description:
      "Aprender inglés puede ser divertido y enriquecedor con nuestras actividades estimulantes.",
    icon: "fas fa-paint-brush",
    bg: "bg-proRed",
  },

  {
    tittle: "Certificaciones",
    description:
      "Prepárate para certificaciones internacionales que impulsarán tu crecimiento académico y profesional.",
    icon: "fas fa-certificate",
    bg: "bg-proYellow",
  },

  {
    tittle: "Ambientes tranquilos de aprendizaje",
    description:
      "Nuestro entorno acogedor y relajado te permite concentrarte en el aprendizaje sin distracciones, fomentando tu progreso constante.",
    icon: "fas fa-leaf",
    bg: "bg-proGray",
  },
];

export const dataFAQs = [
  {
    question:
      "¿Cómo puedo inscribirme en el curso de preparación para el TOEFL ITP de 6 meses?",
    answer:
      "Puedes inscribirte en el curso de preparación para el TOEFL ITP visitando la institucion. También puedes contactarnos a través de nuestro formulario de contacto para obtener mas información.",
  },
  {
    question: "¿Cuáles son las tarifas de los cursos y las opciones de pago?",
    answer:
      "Las tarifas varían según el curso y la duración. Ponte en contacto con nosotros a través del formulario de contacto para obtener más información sobre los costos.",
  },
  {
    question: "¿Ofrecen clases de inglés en presenciales o en linea?",
    answer:
      "Ofrecemos ambas opciones. Las clases presenciales te permiten interactuar con instructores y compañeros en tiempo real, mientras que las clases en linea pueden ser tomadas desde casa.",
  },
  {
    question:
      "¿Ofrecen pruebas de nivel para determinar en qué fase del curso de inglés debería comenzar?",
    answer:
      "Sí, ofrecemos pruebas de nivel para evaluar tu competencia en inglés. Esto nos ayuda a colocarte en la fase adecuada para tu nivel de habilidad.",
  },
  {
    question:
      "¿Cuáles son los requisitos técnicos para participar en las clases virtuales de inglés?",
    answer:
      "Necesitarás una computadora o dispositivo con acceso a Internet, un navegador web actualizado y posiblemente auriculares con micrófono.",
  },
  {
    question: "¿Cuál es la duración de cada fase del curso de inglés ai-ae?",
    answer:
      "Cada fase de nuestro curso de inglés dura aproximadamente tres meses, lo que significa que el programa completo tiene una duración de 24 meses. Sin embargo, la duración puede variar según tu progreso.",
  },

  {
    question:
      "¿Qué tipo de certificación obtendré al completar el curso de inglés ai-ae?",
    answer:
      "Al completar con éxito todas las fases del curso, podras obtener una certificación de Prolearn que demuestra tu dominio del idioma inglés. También puedes optar por tomar el examen TOEFL ITP.",
  },
];

export const dataAboutInfo = [
  {
    title: "Nuestro",
    span: "Compromiso",
    description:
      "Ofrecer servicios educativos integrales de calidad. Contribuir al desarrollo social a través de la educación tanto en el ámbito profesional, así como en el personal. Ser una institución que impacte en la vida de nuestros estudiantes mediante la educación y la cultura, los cuáles concebimos como los principales agentes que generan bienestar y prosperidad.",
    img: "images/about/about3.png",
    flexrow: "lg:flex-row",
    flexcol: "flex-col-reverse",
    colorSpan: "text-proBlue",
    bg: "bg-ProGray2",
  },
  {
    title: "Nuestra",
    span: "Perspectiva ",
    description:
      "Convertirnos en la mejor opción de servicios educativos español – inglés. Brindar servicios y soluciones educativas bilingües, a individuales, empresas, instituciones particulares y de gobierno, colaborando según las características y necesidades de cada ente para contribuir a su éxito.",
    img: "images/about/about2.png",
    flexrow: "lg:flex-row-reverse",
    flexcol: "flex-col-reverse",
    colorSpan: "text-proRed",
    bg: "bg-white",
  },
];

export const dataCourses1 = [
  {
    type: "Regularización",
    title: "Objetivo",
    span: `TOEFL ${""}`,
    span1: "ITP",
    bgSpan: "bg-proRed",
    colorSpan: "text-proBlue",
    colorSpan1: "text-proRed",
    flexrow: "lg:flex-row",
    bgsection: "bg-proGray2",
    description:
      "Logra el puntaje que requieres en el examen TOEFL ITP con nuestro exclusivo programa de regularización y preparación para el examen. Enfocados en tu éxito, te proporcionamos todas las herramientas necesarias para alcanzar tu meta. Nuestro enfoque personalizado y nuestras estrategias probadas te guiarán paso a paso hacia el éxito en tu meta. ",
    description1: "Duración: 24 semanas",
    description2: "Beneficios de nuestro curso:",
    img: "images/courses/courses1.png",
    list: [
      {
        title: "Experiencia:",
        description: "Guía de docentes experimentados. ",
        colorUl: "text-proBlue",
      },
      {
        title: "Plan de estudios:",
        description: "Meticulosamente diseñado para garantizar tu éxito.",
        colorUl: "text-proRed",
      },
      {
        title: "Conveniencia:",
        description:
          "Pro Learn es Centro certificador oficial de TOEFL, por lo tanto haces el examen con nosotros al terminar el curso. ",
        colorUl: "text-proYellow",
      },
      {
        title: "Garantía:",
        description:
          "Resultados tangibles respaldados por mas de 500 estudiantes certificados.",
        colorUl: "text-proBlue",
      },
    ],
  },

  {
    type: "Programa",
    span: `ai ${""}- ${""}`,
    span1: "ae",
    bgSpan: "bg-proYellow",
    colorSpan: "text-proBlue",
    colorSpan1: "text-proYellow",
    flexrow: "lg:flex-row-reverse",
    bgsection: "bg-white",
    description:
      "¡Descubre el increíble poder de tu mente para aprender inglés con nuestro revolucionario sistema de Aprendizaje de Inglés de Alta Eficacia, ai-ae! Basado en teorías de neuro aprendizaje, ai-ae te ofrece un enfoque de estudio natural, relajante y, lo más importante, altamente efectivo.",
    description1:
      "Nuestro método está cuidadosamente diseñado para centrarse en las habilidades de listening y speaking, las cuales son fundamentales para la fluidez y la comunicación efectiva en inglés. Pero no te preocupes, ¡también nos aseguramos de fortalecer todas las demás habilidades del idioma!",
    img: "images/courses/courses3.png",
    list: [
      {
        title: "Duración:",
        description: " 8 niveles de 12 semanas cada uno.",
        colorUl: "text-proBlue",
      },
    ],
  },
];

export const dataTerminosCondiciones = [
  {
    title: "Uso del Sitio",
    description:
      "Nuestro sitio web está destinado únicamente para fines educativos y de aprendizaje del idioma inglés. No se permite el uso del sitio para otros fines distintos a los establecidos.",
  },
  {
    title: "Propiedad Intelectual",
    description:
      "Todo el contenido del sitio, incluyendo pero no limitado a textos, imágenes, videos, y materiales de enseñanza, están protegidos por derechos de propiedad intelectual y son propiedad exclusiva de nuestra empresa. Queda prohibida cualquier reproducción, distribución o uso no autorizado de dicho contenido.",
  },
  {
    title: "Registro y Cuenta de Usuario",
    description:
      "Para acceder a ciertos servicios y recursos del sitio, puede que se requiera el registro y la creación de una cuenta de usuario. Es responsabilidad del usuario mantener la confidencialidad de su información de inicio de sesión y notificar cualquier uso no autorizado de su cuenta.",
  },
  {
    title: "Política de Privacidad",
    description:
      "Nos comprometemos a proteger la privacidad de nuestros usuarios. Por favor, consulta nuestra Política de Privacidad para obtener más información sobre cómo recopilamos, utilizamos y protegemos tus datos personales.",
  },
];

export const dataAvisoPrivacidad = [
  {
    title: "Recopilación de Información",
    description:
      "Podemos recopilar cierta información personal de los usuarios, como nombres, direcciones de correo electrónico y datos de contacto, con el fin de proporcionar servicios personalizados y mejorar la experiencia del usuario en nuestro sitio.",
  },
  {
    title: "Uso de la Información",
    description:
      "Utilizamos la información recopilada para administrar cuentas de usuario, procesar pagos, enviar comunicaciones promocionales y mejorar nuestros servicios y recursos educativos.",
  },
  {
    title: "Seguridad de los Datos",
    description:
      "Implementamos medidas de seguridad para proteger la información personal de nuestros usuarios contra accesos no autorizados, pérdidas o alteraciones.",
  },
  {
    title: "Divulgación de Información",
    description:
      "No compartiremos ni venderemos la información personal de nuestros usuarios a terceros sin su consentimiento expreso, excepto en los casos establecidos por la ley.",
  },
];

export const dataCertificacion = [
  {
    description:
      "Certifica el dominio del idioma inglés bajo el Marco Común Europeo de Referencia",
    colorUl: "text-proBlue",
  },
  {
    description:
      "Genera un puntaje de 310 a 677 puntos, y según el puntaje que es obtenido por el sustentante, asigna un nivel de A2 a C1 de acuerdo al MCER.",
    colorUl: "text-proRed",
  },
  {
    description: "Tiene una validez de 2 años. ",
    colorUl: "text-proYellow",
  },
  {
    description:
      "Es una certificación aceptada como requisito para titulación, examen de ubicación y diagnóstico, para instituciones de educación media superior y superior. ",
    colorUl: "text-proBlue",
  },
  {
    description:
      "Es un certificado con el cual se puede tramitar la CENNI (Constancia Nacional de Nivel de Idioma). ",
    colorUl: "text-proRed",
  },
  {
    description:
      "Son las instituciones que aceptan el TOEFL ITP como certificación valida, quiénes determinan el puntaje que requieren los sustentantes. ",
    colorUl: "text-proYellow",
  },
];

export const dataExamTOEFL = [
  {
    title: "Listening Comprehension",
    asserts: "50 aciertos",
    bg: "bg-proBlue",
    icon: "fas fa-headphones",
  }, 
  {
    title: "Structure & Written Expression",
    asserts: "40 aciertos",
    bg: "bg-proRed",
    icon: "fas fa-pen",
  }, 
  {
    title: "Reading Comprehension",
    asserts: "50 aciertos",
    bg: "bg-proYellow",
    icon: "fas fa-book",
  },
  {
    title: "Duración",
    asserts: "La aplicación del examen se realiza en un periodo de tiempo de aproximadamente 3 horas. ",
    bg: "bg-proBlue",
    icon: "fas fa-clock",
  },
  
]

export const dataGridRegister = [
  {
    number: "01",
    title: "Identificación Oficial",
    description: "Identificación Oficial con fotografía, misma que tendrás que presentar el día del examen.",
    bg: "bg-proBlue",
  },
  {
    number: "02",
    title: "Formulario",
    description: "Llenar el formulario que se te proporcionará a través de nuestro WhatsApp. ",
    bg: "bg-proRed",
  },
  {
    number: "03",
    title: "Pago",
    description: "Comprobante de pago, que se puede realizar por medio de transferencia.",
    bg: "bg-proYellow",
  },
]

export const dataGridTypeTOEFL = [
  {
    title: "TOEFL ITP En papel",
    description: "Se aplica directamente en nuestras instalaciones. ",
    bg: "bg-proBlue",
    icon: "fas fa-file-alt",
  },
  {
    title: "TOEFL ITP a distancia",
    description: "Se aplica a distancia, en casa u oficina y se requieren dos dispositivos. ",
    bg: "bg-proRed",
    icon: "fas fa-globe",
  },
  {
    title: "TOEFL ITP en línea",
    description: "Se aplica en nuestras instalaciones en computadora.",
    bg: "bg-proYellow",
    icon: "fas fa-laptop",
  },
]