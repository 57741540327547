import { ButtonYellow } from "../app/Buttons";

export const AboutHeader = () => {
  return (
    <section className="bg-hero sm:py-36 pt-28 flex lg:flex-row flex-col items-center justify-center lg:justify-between lg:space-y-0 space-y-20 lg:space-x-20 lg:px-28 md:px-14 px-7">
      <div className="flex flex-col lg:w-1/2 sm:space-y-10 ">
        <h1 className="lg:text-5xl md:text-4xl text-3xl  font-bold text-proGray1">
          Somos <span className="text-proYellow"> </span>
          <span className="text-proBlue">PRO</span>
          <span className="text-proRed">learn </span>
        </h1>

        <p className="text-proGray1 my-5 lg:text-lg">
          Hace 5 años, nació PRO Learn con la firme convicción de que el dominio
          del inglés es una herramienta fundamental para impulsar la prosperidad
          en la vida de nuestros estudiantes. Nos apasiona enseñar inglés y
          además ¡lo hacemos muy bien!
        </p>

        <div className="">
        <ButtonYellow to="/cursos">Ver cursos</ButtonYellow>
        </div>
      </div>

      <div className="lg:w-1/2 w-full">
        <img
          src="images/about/heroabout.png"
          alt="Group Prolearn"
          className="w-full"
        />
      </div>
    </section>
  );
};
