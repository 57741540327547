import { Form } from "../contact/Form";
import { Map } from "../contact/Map";

export default function ContactSection() {
  return (
    <>
      <section className="flex flex-col justify-center items-center lg:px-28 md:px-14 px-7 sm:py-28 py-28 min-h-screen space-y-10">
        <div className="space-y-5">
          <h1 className="text-proGray1 font-bold lg:text-5xl md:text-4xl text-2xl text-center">
            <span className="text-proBlue">Contacta </span> con
            <span className="text-proRed"> nosotros </span>
          </h1>
          <p className="text-lg text-center">
            ¿Tiene preguntas o comentarios? Estamos aquí para ayudar. Envíanos
            un mensaje y te responderemos.
          </p>
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 lg:w-[85%] w-full">
          <div className="bg-white shadow-md md:px-10 px-4 py-5 rounded-2xl border-2 border-proGray2">
            <Form />
          </div>

          <div className="grid grid-rows-2 gap-5 rounded-2xl">
            <div className="bg-white shadow-md p-4 rounded-2xl border-2 gap-5 border-proGray2 grid grid-rows-2 items-center">
              {data.map((item, index) => (
                <div key={index} className="bg-proGray2 rounded-2xl p-4">
                <div className="flex items-center space-x-4">
                {index === data.length - 1 ? ( 
                      <a href={item.href} target="_blank" rel="noopener noreferrer" className={`flex items-center justify-center ${item.bg} w-10 h-10 rounded-full transition-colors duration-300 ease-in-out hover:bg-green-600`}>
                        <i className={`${item.icon} text-white`} />
                      </a>
                    ) : (
                      <span className={`flex items-center justify-center ${item.bg} w-10 h-10 rounded-full`}>
                        <i className={`${item.icon} text-white`} />
                      </span>
                    )}
                  <div className="space-y-1">
                    <label className="font-semibold sm:text-lg text-base">
                      {item.medio}
                    </label>
                    <p className="text-sm">{item.info}</p>
                  </div>
                </div>
              </div>
              ))}
            </div>
            <div>
              <Map />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const data = [
    {
        medio:"Correo electrónico",
        info:"soyprolearn@gmail.com",
        icon:"fas fa-envelope",
        bg:"bg-proBlue",
    },
    {
        medio:"Teléfono",
        info:"(+52) 7122592802",
        icon:"fas fa-phone",
        bg:"bg-proRed",
    },
    {
        medio:"Únete",
        info:"a nuestra comunidad",
        icon:"fab fa-whatsapp",
        bg:"bg-[#25D366]",
        href:"https://chat.whatsapp.com/D41AT39Og6lFlxaVnDRRac"
    }
]