import { ButtonYellow } from "../app/Buttons";

export const CoursesHome = () => {
  return (
    <section className=" lg:px-28 md:px-14 px-7 sm:py-28 py-14">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
        <div className="border-2 border-[#F5F5F5] p-6 rounded-2xl h-full flex flex-col justify-center items-center space-y-10 shadow-sm ">
          <h1 className="lg:text-5xl md:text-4xl text-2xl font-bold text-proGray1 text-center">
            <span className="text-proBlue">Explora</span> nuestros
            <span className="text-proRed"> cursos </span>
          </h1>
          <p className="pt-6 text-center sm:text-base text-sm max-w-lg">
            Descubre un mundo de oportunidades con nuestros cursos de inglés.
            Aprende con expertos y alcanza tus
            metas personales y profesionales. ¡Inscríbete hoy mismo!
          </p>

          <ButtonYellow to="/cursos">Ver cursos</ButtonYellow>
        </div>

        <div className="grid grid-rows-2 gap-5">
          {data.map((course, index) => (
            <div key={index} className={`w-full border-2 border-[#F5F5F5] rounded-2xl shadow-sm flex ${course.flexrow} justify-between flex-col`}>
            <div className="p-6 space-y-5 ">
              <span className={`${course.bg} py-1 px-4 rounded-full text-white font-medium text-xs`}>
                {course.type}
              </span>
              <h1 className="pt-3 font-semibold text-xl">{course.title}</h1>
              <p className="md:max-w-xl text-sm">
                {course.description}
              </p>
            </div>
            <div className="">
              <img
                src={course.img}
                alt="TOEFL"
                className="w-[600px] h-full object-cover rounded-b-2xl"
              />
            </div>
          </div>
          ))}
          
        </div>
      </div>
    </section>
  );
};


const data = [
  {
    type: "Rregularización",
    title: "Objetivo TOEFL ITP",
    description:
      "El objetivo del curso TOEFL ITP es proveer las herramientas necesarias para que obtengas el puntaje que estás buscando.",
    img: "images/courses/toefl.png",
    bg: "bg-proBlue",
    flexrow: "2xl:flex-row",
  },
  {
    type: "Programa",
    title: "ai-ae",
    description:
      "¡Descubre el increíble poder de tu mente para aprender inglés con nuestro revolucionario sistema de Aprendizaje de Inglés de Alta Eficacia, ai-ae! Basado en teorías de neuro aprendizaje.",
    img: "images/courses/toefl1.png",
    bg: "bg-proYellow",
    flexrow: "2xl:flex-row-reverse",
  }
]