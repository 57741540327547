import { ButtonYellow } from "../app/Buttons";

export const HeaderCourses = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-10 lg:px-28 md:px-14 px-7 sm:py-28 py-14 sm:min-h-screen bg-white ">
      <div className="lg:text-5xl md:text-4xl text-2xl font-bold sm:space-y-7 space-y-3 flex flex-col items-center">
        <h1 className="text-center text-proGray1">
          <span className="text-proBlue">PRO</span>
          <span className="text-proRed">learn</span>
        </h1>
        <h1 className="text-center text-proGray1">
          ¡Tu mejor aliado para aprender {""}
          <span className="text-proYellow">inglés! {""}</span>
        </h1>

        <p className="sm:text-base text-sm font-normal text-proGray1 max-w-2xl text-center">
          ¡Desbloquea tu futuro con Prolearn!
          ¿Estás listo para dominar el inglés? No esperes más y
          empieza tu emocionante viaje hacia el éxito.
        </p>
      </div>
      <ButtonYellow to="/contacto">Contáctanos</ButtonYellow>
    </div>
  );
};
