import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactSection from "../components/contact/ContactSection";
import Seo from "../seo/Seo";

export default function Contact() {
  return (
    <div>
      <Seo
        title="Contacto"
        description="¿Tienes preguntas o necesitas más información sobre nuestros cursos y servicios? ¡No dudes en ponerte en contacto con nosotros! Nuestro equipo amable y experimentado está aquí para ayudarte en cada paso del camino hacia el dominio del inglés."
      />
      <NavBar bgColor="bg-white" />
      <div className="bg-hero">
        <ContactSection />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
