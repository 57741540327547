import { dataExamTOEFL } from "../../utils/FullData";

export const SkillsCertification = () => {
  return (
    <>
      <section className="min-h-screen flex xl:flex-row flex-col-reverse items-center justify-between w-full xl:px-28 md:px-14 px-7 xl:py-0 md:py-28 py-14 xl:space-x-10">
        <div className="lg:w-1/2 md:w-3/4 pt-10">
          <img
            src="images/certificacion/certi.png"
            alt="Imagen de certificacion"
            className="w-full object-cover"
          />
        </div>

        <div className="sm:space-y-20 space-y-14 xl:w-3/4">
          <h1 className="lg:text-5xl md:text-4xl text-2xl font-bold text-proGray1 text-center">
            <span className="text-proBlue">Estructura</span> del {""}
            <span className="text-proRed">examen</span>
          </h1>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-10 ">
            {dataExamTOEFL.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center space-y-5"
              >
                <span className={`text-white w-12 h-12 rounded-full ${item.bg} flex items-center justify-center`}>
                  <i className={`${item.icon}`} />
                </span>
                <h2 className="sm:text-xl font-bold text-proGray1 text-center">
                  {item.title}
                </h2>
                <p className="sm:text-base text-sm text-center ">{item.asserts}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
