import { useEffect, useState } from "react";
import { dataSocial } from "../utils/FullData";
import { motion } from "framer-motion";
import { Enlaces } from "./app/Enlaces";

export default function NavBar({ bgColor }) {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setHasScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="z-40 w-full">
      <nav
        className={`flex items-center justify-between lg:px-24 md:px-12 px-7 py-2 w-full fixed ${
          hasScrolled ? bgColor : "bg-transparent"
        } z-10`}
      >
        <motion.a whileTap={{ scale: 0.9 }} href="/" className="sm:w-20 w-16">
          <img
            src="images/app/logo_completo.png"
            alt="Logo Prolearn"
            className="w-full sm:h-20 h-16"
            draggable="false"
          />
        </motion.a>

        <div className="xl:space-x-10 space-x-6 md:flex items-center hidden">
          <Enlaces to="/">Inicio</Enlaces>
          <Enlaces to="/sobre-nosotros">Sobre nosotros</Enlaces>
          <Enlaces to="/cursos">Cursos</Enlaces>
          <Enlaces to="/certificacion-TOEFL-ITP">
            Certificación TOEFL ITP
          </Enlaces>
          <Enlaces to="/contacto">Contacto</Enlaces>
        </div>

        <div className="hidden lg:flex xl:space-x-3 space-x-1">
          {dataSocial.map((social, index) => {
            return (
              <div key={index}>
                <a
                  href={social.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={`flex items-center justify-center w-8 h-8 text-white rounded-full text-sm ${social.bg} rounded-full transition-transform transform scale-100 hover:scale-110 duration-300 hover:opacity-80`}
                >
                  <i className={`${social.icon} relative`} />
                </a>
              </div>
            );
          })}
        </div>

        <div className="md:hidden flex">
          <motion.button
            onClick={() => setIsOpen(!isOpen)}
            whileTap={{ scale: 0.85 }}
            className="text-proBlue uppercase text-3xl"
          >
            <i className="fas fa-bars" />
          </motion.button>
        </div>
      </nav>

      <div
        className={`${
          isOpen ? "fixed inset-0 overflow-hidden" : "hidden"
        } w-full min-h-screen bg-white backdrop-blur-lg bg-opacity-60 z-50`}
      >
        <div className="flex flex-col items-center justify-center px-7 py-2">
          <div className="flex justify-between w-full ">
            <motion.a whileTap={{ scale: 0.9 }} href="/" className="w-16">
              <img
                src="images/app/logo_completo.png"
                alt="Logo Prolearn"
                className="w-full h-16"
                draggable="false"
              />
            </motion.a>

            <motion.button
              onClick={() => setIsOpen(!isOpen)}
              whileTap={{ scale: 0.85 }}
              className=""
            >
              <i className="fas fa-xmark text-proBlue text-4xl" />
            </motion.button>
          </div>

          <div className="space-y-10 flex flex-col items-center pt-36">
            <Enlaces to="/">Inicio</Enlaces>
            <Enlaces to="/sobre-nosotros">Sobre nosotros</Enlaces>
            <Enlaces to="/cursos">Cursos</Enlaces>
            <Enlaces to="/certificacion-TOEFL-ITP">
              Certificación TOEFL ITP
            </Enlaces>
            <Enlaces to="/contacto">Contacto</Enlaces>

            <div className="flex items-center space-x-3">
              {dataSocial.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={`flex items-center justify-center w-10 h-10 text-white rounded-full text-sm ${social.bg} rounded-full transition-transform transform scale-100 hover:scale-110 duration-300 hover:opacity-80`}
                >
                  <i className={`${social.icon} relative`} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}