import NavBar from "../components/NavBar";
import { BenefitsCertification } from "../components/certificacionTOEFL/BenefitsCertification";
import { HeroCertificacion } from "../components/certificacionTOEFL/HeroCertificacion";
import { RegisterCertification } from "../components/certificacionTOEFL/RegisterCertification";
import { SkillsCertification } from "../components/certificacionTOEFL/SkillsCertification";
import Footer from "../components/Footer";
import Seo from "../seo/Seo";
import { ContactCertificacion } from "../components/certificacionTOEFL/ContactCertificacion";
export default function CertificacionTOEFL() {
  return (
    <>
      <div>
        <Seo
          title="Certificación TOEFL ITP"
          description="Prepárate para alcanzar tus metas académicas y profesionales con nuestro programa de preparación para la certificación TOEFL ITP. Descubre cómo nuestras herramientas especializadas y recursos de vanguardia te ayudarán a obtener el puntaje que necesitas para avanzar en tu carrera o educación."
        />
        <NavBar bgColor="bg-white" />
        <main>
          <HeroCertificacion />
          <BenefitsCertification />
          <SkillsCertification />
          <RegisterCertification />
          <ContactCertificacion />
        </main>
        
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
}
