export const Map = () => {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.4954143093746!2d-99.76991522478231!3d19.563265081744266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d26573ad4140a5%3A0xebbf512024d95d4!2sProLearn!5e0!3m2!1ses!2smx!4v1708140045750!5m2!1ses!2smx"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="mapa"
        className="w-full h-full rounded-2xl"
      />
    );
  };
  