import { dataCourses1 } from "../../utils/FullData";

export const CarrouselCourses = () => {
  return (
    <>
      <section>
        <div>
          {dataCourses1.map((course, index) => (
            <div
              key={index}
              className={` w-full flex ${course.flexrow} ${course.bgsection} flex-col justify-between items-center min-h-screen lg:px-28 md:px-14 px-7 lg:py-0 py-14 lg:space-x-10 lg:space-y-0 space-y-10`}
            >
              <div className="space-y-10">
                <div className=" space-y-2">
                  <span
                    className={`${course.bgSpan} text-white py-1 px-4 rounded-2xl uppercase font-semibold text-xs`}
                  >
                    {course.type}
                  </span>
                  <h3
                    className={`lg:text-4xl md:text-3xl text-2xl font-bold text-proGray1 pt-2`}
                  >
                    {course.title}{" "}
                    <span className={`${course.colorSpan}`}>{course.span}</span>
                    <span className={`${course.colorSpan1}`}>
                      {course.span1}
                    </span>
                  </h3>
                </div>

                <div className="space-y-3 lg:max-w-2xl">
                  <p className="text-base"> {course.description} </p>
                  <p className="text-base"> {course.description1} </p>
                  <p className="text-base"> {course.description2} </p>
                  <ul className="list-disc text-base">
                    {course.list.map((list, index) => (
                      <li
                        className={`font-semibold lg:ml-14 ml-7 ${list.colorUl}`}
                        key={index}
                      >
                        {list.title}{" "}
                        <span className="font-normal text-proGray1">
                          {list.description}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="lg:w-[600px]">
                <img src={course.img} alt={course.title} className="object-cover" />
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
