import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { HeroHome } from "../components/home/HeroHome";
import { Truble } from "../components/home/Truble";
import { CoursesHome } from "../components/home/CoursesHome";
import { HeaderHome } from "../components/home/Header";
import { Reviews } from "../components/home/Reviews";
import Seo from "../seo/Seo";

export default function Home() {
  return (
    <>
      <Seo
        title="Inicio"
        description="Explora el mundo de oportunidades que ofrece ProLearn, donde la excelencia en la enseñanza del inglés se encuentra con la comodidad del aprendizaje en línea. Descubre nuestro enfoque innovador y únete a una comunidad global de estudiantes dedicados a alcanzar sus metas lingüísticas."
      />
        <NavBar bgColor="bg-white" />
      <div>
        <HeroHome />
        <Truble />
        <CoursesHome />
        <Reviews />
        <HeaderHome />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
