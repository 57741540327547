import { ButtonYellow } from "../app/Buttons";

export const HeroHome = () => {
  return (
    <section className="bg-hero sm:flex inline-block items-center md:space-x-20 sm:space-x-10 space-x-0 lg:px-28 md:px-14 px-7 sm:min-h-screen min-h-[650px] sm:pt-16 pt-32">
      <div className=" md:w-1/2 text-proGray1 space-y-5">
        <p className="text-xs font-bold">Cursos presenciales y en línea.</p>
        <h1 className="lg:text-5xl md:text-4xl text-3xl font-bold">
          <span className="text-proYellow">¡Somos </span> los{" "}
          <span className="text-proBlue"> mejores</span>
          <br />
          enseñando <span className="text-proRed  "> inglés! </span>
        </h1>
        <p className="text-xs md:text-lg ">
          Si no aún no has aprendido el idioma tal vez es porque no te lo han
          enseñado bien. ¡Nosotros somos expertos!
        </p>

        <div className="sm:pt-16 pt-10">
          <ButtonYellow to="/sobre-nosotros">Sobre Prolearn</ButtonYellow>
        </div>
      </div>

      <div className="md:w-2/5 mt-7 sm:mt-0">
        <img src="images/home/banner_home.png" alt="Banner" className="w-full" />
      </div>
    </section>
  );
};
