import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { CarrouselCourses } from "../components/courses/CarrouselCourses";
import { HeaderCourses } from "../components/courses/HeaderCourses";
import { HeroCourses } from "../components/courses/HeroCourses";
import { Benefits } from "../components/home/Benefits";
import Seo from "../seo/Seo";

export default function Courses() {
  return (
    <>
      <Seo
        title="Cursos"
        description="Sumérgete en nuestra amplia variedad de cursos de inglés en línea diseñados para adaptarse a tus necesidades y objetivos individuales. Desde principiantes hasta avanzados, nuestros cursos ofrecen una experiencia de aprendizaje única y efectiva respaldada por expertos en el idioma"
      />
      <NavBar bgColor="bg-white" />
      <main>
        <HeroCourses />
        <CarrouselCourses />
        <Benefits />
        <HeaderCourses />
        <Footer />
      </main>
    </>
  );
}
