import { ButtonYellow } from "../app/Buttons";

export default function Error404() {
  return (
    <>
      <section className="bg-hero">
        <div className="bg-error min-h-screen flex flex-col justify-center items-center w-full">
          <div className="md:w-32 md:h-32 w-20 h-20 absolute md:left-10 left-5 top-5">
            <img src="images/app/logo_completo.png" alt="" className="" />
          </div>

          <div className="md:space-y-10 space-y-10 flex flex-col items-center justify-center">
            <h1 className="font-semibold md:text-6xl sm:text-5xl text-3xl text-proGray1">Error</h1>
            <h1 className="font-semibold md:text-9xl sm:text-8xl text-6xl text-proBlue">404</h1>
           

            <div className="space-y-2">
            <h1 className="md:text-xl text-base text-proGray1 font-semibold text-center">Página no encontrada</h1>
            <p className="md:text-base text-sm text-proGray1 text-center">Esta página no existe o se movió a otro lugar.</p>
            </div>
            <ButtonYellow to="/inicio">Volver al inicio </ButtonYellow>
          </div>
        </div>
      </section>
    </>
  );
}
