import { ButtonYellow } from "../app/Buttons";

export const HeaderHome = () => {
  return (
    <section className="flex flex-col items-center justify-center space-y-10 lg:px-28 md:px-14 px-7 sm:py-28 py-14 sm:min-h-screen ">
      <div className="lg:text-5xl md:text-4xl text-2xl font-bold sm:space-y-7 space-y-3 flex flex-col items-center">
      <h1 className="text-center text-proGray1">
          <span className="text-proBlue">PRO</span>
          <span className="text-proRed">learn</span>
        </h1>
        <h1 className="text-center text-proGray1">
          ¡Expertos en {""}
          <span className="text-proYellow">inglés! {""}</span>
        </h1>
        
        <p className="sm:text-base text-sm font-normal text-proGray1 max-w-2xl text-center">
          ¡No lo dudes más y sé parte de nuestra comunidad mientras trabajas
          para obtener tu certificación TOEFL ITP! Haz clic en este momento para
          explorar nuestra oferta educativa y dar inicio a tu emocionante viaje
          hacia la maestría del idioma.
        </p>
      </div>
      <ButtonYellow to="/contacto">Contáctanos</ButtonYellow>
    </section>
  );
};
