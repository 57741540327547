import { NavLink, useLocation } from "react-router-dom";

export function Enlaces({ to, children }) {
  const location = useLocation();

  return (
    <NavLink
      to={to}
      rel="noopener noreferrer"
      className={`sm:text-base text-lg ${
        location.pathname === to
          ? "text-proBlue font-bold border-b border-proBlue"
          : "font-normal text-proGray1 hover:text-proBlue transition-colors duration-300 hover:border-b hover:border-proBlue w-fit"
      }`}
    >
      {children}
    </NavLink>
  );
}

export function NormalLink({ to, children }) {
  return (
    <NavLink
      to={to}
      rel="noopener noreferrer"
      className=" text-proGray1 hover:text-proBlue hover:border-b hover:border-proBlue w-fit transition-colors duration-300"
    >
      {children}
    </NavLink>
  );
}
