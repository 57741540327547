import React, { useEffect } from 'react';

export default function Seo({ title, description }) {
  const defaultTitle = "Prolearn ¡Expertos en  inglés!";
  const defaultDescription = "¡Bienvenido a ProLearn! Descubre nuestros cursos, recursos educativos y servicios diseñados para impulsar tu éxito lingüístico. Con un enfoque innovador y expertos en enseñanza, estamos aquí para ayudarte a alcanzar tus metas de aprendizaje de manera accesible y conveniente. Únete a nuestra comunidad de estudiantes dedicados y comienza tu emocionante viaje hacia el dominio del idioma inglés con ProLearn.";

  useEffect(() => {
    document.title = title ? `${defaultTitle} - ${title}` : defaultTitle;
    return () => {
      document.title = defaultTitle;
    };
  }, [title]);

  return (
    <>
      <title>{title ? `${defaultTitle} | ${title}` : defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:title" content={title ? `${defaultTitle} | ${title}` : defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
    </>
  );
}
