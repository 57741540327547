export const HeroCertificacion = () => {
  return (
    <>
      <div className="bg-hero">
        <div className="bg-toefl min-h-screen w-full flex flex-col justify-center items-center lg:px-28 md:px-14 px-7 md:space-y-8 space-y-6">
          <h1 className="lg:text-6xl md:text-5xl text-4xl font-bold text-proGray1 text-center">
           Certificación
          </h1>
          <br className="sm:flex hidden" />
          <h1 className="lg:text-9xl md:text-8xl text-6xl font-bold text-proGray1 text-center italic">
           <span className="text-proBlue">TOEFL</span> {""}
           <span className="text-proRed">ITP</span>
          </h1>
          {/* <div>
            <img src="images/app/logo_toefl.png" alt="Logo TOEFL" className="object-cover"/>
          </div> */}
          <p className="sm:text-xl text-base">Compatible con <span className="font-bold italic">CENNI</span></p>
        </div>
      </div>
    </>
  );
};
