import NavBar from "../components/NavBar";
import { Commnigsoon } from "../components/common/Commnigsoon";
import Seo from "../seo/Seo";

export default function Products() {
  return (
    <main>
      <Seo
        title="Productos"
        description="Explora nuestra selección de productos de calidad que complementan tu experiencia de aprendizaje en ProLearn."
      />
      <NavBar bgColor="bg-white" />`
      <Commnigsoon title="Productos" />
    </main>
  );
}
