import { dataCertificacion } from "../../utils/FullData";

export const BenefitsCertification = () => {
  return (
    <>
      <section className="pb-10">
        <div>
          <div className= "w-full flex lg:flex-row flex-col justify-between items-center min-h-screen lg:px-28 md:px-14 px-7 sm:py-28 py-14 lg:space-x-10 lg:space-y-0 space-y-10">
            <div className="space-y-10">
              <h1 className="lg:text-5xl md:text-4xl text-2xl font-bold text-proGray1 text-center">
                <span className="text-proBlue">TOEFL</span> {""}
                <span className="text-proRed">ITP</span>
              </h1>
              <div className="space-y-5 lg:max-w-2xl">
                <p className="text-base">
                  PRO Learn, cuenta con convenio con el Institute of
                  International Education, IIE, para administrar exámenes TOEFL
                  ITP con validez internacional. El TOEFL ITP es un examen
                  institucional que:{" "}
                </p>
                <ul className="list-disc text-base">
                  {dataCertificacion.map((list, index) => (
                    <li
                      className={`font-semibold lg:ml-14 ml-7 ${list.colorUl}`}
                      key={index}
                    >
                      <span className="font-normal text-proGray1">
                        {list.description}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="lg:w-[800px]">
              <img
                src="images/certificacion/certi_banner.png"
                alt="Banner Ceritificación TOEFL"
                className="object-cover"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
