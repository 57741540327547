import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Seo from "../seo/Seo";
import { dataAvisoPrivacidad } from "../utils/FullData";

export default function Aviso() {
  return (
    <div>
      <Seo
        title="Aviso de privacidad"
        description="Protegemos tu privacidad en ProLearn. Descubre cómo manejamos tus datos personales y qué medidas tomamos para garantizar la seguridad y confidencialidad de tu información "
      />
      <div className="relative">
        <NavBar bgColor="bg-white" />
      </div>
      <div className="lg:px-28 md:px-14 px-7 sm:py-36 py-28 md:space-y-20 space-y-14">
        <h1 className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl font-bold text-proGray1">
          <span className="text-proBlue">Aviso</span> de {""}
          <span className="text-proRed">privacidad</span>
        </h1>
        <div className="md:px-12 space-y-10">
          {dataAvisoPrivacidad.map((item, index) => (
            <div key={index} className=" space-y-5">
              <h2 className="md:text-3xl text-xl font-bold text-proGray1 uppercase">
                {item.title}
              </h2>
              <p className="text-proGray1 md:text-lg text-sm">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
