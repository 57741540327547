import { NavLink } from "react-router-dom";

export const Commnigsoon = ({ title }) => {
  return (
    <>
      <div className="space-y-10 min-h-screen flex flex-col justify-center items-center">
        <h1 className="font-semibold md:text-xl sm:text-lg text-base">Algo grandioso está en camino</h1>
        <h2 className="font-semibold text-proRed md:text-5xl sm:text-3xl text-2xl">Próximamente</h2>
        <h1 className="font-bold md:text-9xl sm:text-7xl text-5xl text-proBlue">{title}</h1>
        <NavLink
          to={"/cursos"}
          className="flex items-center justify-center rounded-full w-40 h-12 overflow-hidden group bg-proYellow relative hover:bg-gradient-to-r hover:from-proYellow hover:to-yellow-400 text-proGray1 hover:ring-2 hover:ring-offset-2 hover:ring-yellow-400 transition-all ease-out duration-300 text-xs font-semibold uppercase"
        >
          <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease" />
          <span className="relative">Inicio</span>
        </NavLink>
      </div>
    </>
  );
};
