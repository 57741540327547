import { dataBenifits } from "../../utils/FullData";

export const Benefits = () => {
  return (
    <section className="min-h-screen flex items-center justify-center lg:px-28 md:px-14 px-7 sm:py-28 py-14 bg-proGray2">
      <div className="lg:flex lg:space-x-20 sm:items-center lg:space-y-0 space-y-16">
        <h1 className="lg:text-5xl md:text-4xl text-2xl font-bold text-proGray1 text-center mb-14 sm:mb-0 lg:max-w-xl">
          <span className="text-proBlue">Beneficios </span>de 
          <span className="text-proRed"> acuerdo </span> a tus
          <span className="text-proYellow"> necesidades </span>
        </h1>

        <div className="grid md:grid-rows-2 md:grid-cols-2 gap-8">
          {dataBenifits.map((service, index) => (
            <div key={index}>
              <div className="flex justify-center">
                <div
                  className={`rounded-full flex justify-center items-center w-12 h-12 ${service.bg}`}
                >
                  <i className={`fas ${service.icon} text-white`}></i>
                </div>
              </div>
              <div>
                <h1 className="mt-7 text-base font-bold text-proGray1 text-center">
                  {service.tittle}
                </h1>
                <p className="text-sm mt-4 text-proGray text-center">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
