import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { AboutHeader } from "../components/about/AboutHero";
import { AboutInfo } from "../components/about/AboutInfo";
import Seo from "../seo/Seo";

export default function About() {
  return (
    <>
      <Seo
        title="Sobre nosotros"
        description="En ProLearn, creemos en el poder transformador del dominio del inglés. Descubre nuestra historia, misión y compromiso con la excelencia en la enseñanza del idioma mientras te sumerges en nuestra comunidad global de estudiantes y educadores apasionados."
      />
      <NavBar bgColor="bg-white" />
      <main>
        <AboutHeader />
        <AboutInfo />
      </main>
      <Footer />
    </>
  );
}
