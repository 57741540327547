import { useForm } from "react-hook-form";

export const ContactCertificacion = () => {
  return (
    <>
      <section className="flex flex-col justify-center items-center lg:px-28 md:px-14 px-7 sm:py-28 py-28 min-h-screen sm:space-y-20 space-y-10">
        <div className="space-y-5">
          <h1 className="text-proGray1 font-bold lg:text-5xl md:text-4xl text-2xl text-center">
            <span className="text-proBlue">Envianos </span> un
            <span className="text-proRed"> mensaje </span>
          </h1>
          <p className="sm:text-lg text-center">
            ¿Tiene preguntas? Estamos aquí para ayudar. Envíanos un mensaje a
            través del formulario con la opción de tu interés y te responderemos
            en seguida.
          </p>
        </div>
        <div className="flex lg:flex-row justify-between items-center flex-col lg:space-y-0 space-y-20 lg:space-x-20">
          <div className="bg-white shadow-md md:px-10 px-4 py-5 rounded-2xl border-2 border-proGray2 lg:w-1/2 w-full">
            <Form />
          </div>
          <div className="md:w-1/2 ">
            <img
              src="images/certificacion/certi-form.png"
              alt="Imagen del formulario"
              className=""
            />
          </div>
        </div>
      </section>
    </>
  );
};

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onChange" });

  const senData = async (data) => {
    try {
      const mensaje = `¡Hola! soy *${data.nombre}* 👋%0AMi correo electrónico es: *${data.email}* ✉️%0AEstoy interesado en: *${data.toefl}* %0AY me pongo en contacto con ustedes por el siguiente motivo: *${data.mensaje}* 🤔😊%0A¡Gracias! 📚🌟`;
      const enlaceWhatsApp = `https://api.whatsapp.com/send?phone=7122592802&text=${mensaje}`;
      window.open(enlaceWhatsApp, "_blank");
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(senData)}>
      <div className="flex flex-col space-y-3">
        <label className="text-sm font-semibold text-proGray">Nombre</label>
        <input
          {...register("nombre", { required: true })}
          id="nombre"
          type="text"
          className="h-11 w-full text-sm rounded-lg bg-proGray2 border-proBlue px-4 focus:outline-none focus:ring focus:ring-proBlue"
          placeholder="Ingresa tu nombre"
        />
        {errors.nombre && (
          <span className="required text-xs font-semibold text-red-500">
            * Ingresa tú nombre
          </span>
        )}
      </div>

      <div className="flex flex-col space-y-3">
        <label className="text-sm font-semibold text-proGray">
          Correo electrónico
        </label>
        <input
          {...register("email", {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i,
              message: "* Correo electrónico no válido",
            },
          })}
          id="email"
          type="email"
          className="h-11 w-full text-sm bg-proGray2 rounded-lg border-proBlue px-4 focus:outline-none focus:ring focus:ring-proBlue"
          placeholder="Ingresa tu correo electrónico"
        />
        {errors.email && (
          <span className="required text-xs font-semibold text-red-500">
            * Ingrese tú correo electrónico
          </span>
        )}
      </div>

      <div className="flex flex-col space-y-3">
        <label className="text-sm font-semibold text-proGray">Tipo</label>
        <select
          {...register("toefl", { required: true })}
          id="toefl"
          className="h-11 w-full text-sm bg-proGray2 rounded-lg border-proBlue px-4 focus:outline-none focus:ring focus:ring-proBlue"
        >
          <option value="" disabled className="font-semibold bg-gray-200">
            Elige una opción
          </option>
          <option value="TOEFL ITP en papel">TOEFL ITP en papel</option>
          <option value="TOEFL ITP a distancia">TOEFL ITP a distancia</option>
          <option value="TOEFL ITP en línea">TOEFL ITP en línea</option>
        </select>
        {errors.toefl && (
          <span className="required text-xs font-semibold text-red-500">
            * Selecciona una opción
          </span>
        )}
      </div>

      <div className="flex flex-col space-y-3">
        <label className="text-sm font-semibold text-proGray">Mensaje</label>
        <textarea
          {...register("mensaje", { required: true })}
          id="mensaje"
          className="resize-none h-40 w-full py-4 px-4 text-sm bg-proGray2 rounded-lg border-proBlue focus:outline-none focus:ring focus:ring-proBlue"
          placeholder="Deja tu mensaje"
        />
        {errors.mensaje && (
          <span className="required text-xs font-semibold text-red-500">
            * Ingrese tú mensaje
          </span>
        )}
      </div>
      <button
        disabled={!isValid}
        className={`${
          isValid ? "cursor-pointer" : "opacity-50 cursor-not-allowed"
        }  flex items-center justify-center rounded-full w-40 h-12 overflow-hidden group bg-proYellow relative hover:bg-gradient-to-r hover:from-proYellow hover:to-yellow-400 text-proGray1 hover:ring-2 hover:ring-offset-2 hover:ring-yellow-400 transition-all ease-out duration-300 text-xs font-semibold uppercase`}
      >
        <i className="fab fa-whatsapp mr-3 text-base" />
        {isValid ? "Enviar" : "Contactanos"}
      </button>
    </form>
  );
};
