import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Courses from "./pages/Courses";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import CertificacionTOEFL from "./pages/CertificacionTOEFL";
import Terminos from "./pages/Terminos";
import Aviso from "./pages/Aviso";
import ReactGA from "react-ga4";
import { gtag, install } from "ga-gtag";
import Error404 from "./components/common/Error404";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

ReactGA.initialize([
  { trackingId: "G-M7V0HMJQ56" },
  { trackingId: "AW-16490543430" },  
]);

install("AW-16490543430");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

export const trackingEvents = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const sendConversionToAds = ( sendTo ) => {
  gtag("event", "conversion", { send_to: sendTo });
};

function App() {

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TPTDGMHZ' });
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre-nosotros" element={<About />} />
          <Route path="/cursos" element={<Courses />} />
          <Route
            path="/certificacion-TOEFL-ITP"
            element={<CertificacionTOEFL />}
          />
          <Route path="/productos" element={<Products />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/terminos-y-condiciones" element={<Terminos />} />
          <Route path="/aviso-de-privacidad" element={<Aviso />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
